import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useGetPackageSettings,
  useUpdatePackageSettingsMutation,
} from "src/api/cms";
import * as yup from "yup";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";

const schema = yup.object().shape({
  min_months: yup.string().required("required"),
  discount_percentage: yup.string().required("required"),
  grace_period: yup.string().required("required"),
});

export interface FormFields extends yup.InferType<typeof schema> {}

const Categories = () => {
  const { t } = useTranslation("websiteCms");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // react hook form
  const { control, formState, reset, handleSubmit } = useForm<FormFields>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // APIS
  const { data } = useGetPackageSettings();
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdatePackageSettingsMutation();

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    mutateUpdate({
      min_months: +data.min_months,
      discount_percentage: +data.discount_percentage,
      grace_period: +data.grace_period,
    });
  };

  useEffect(() => {
    if (data) {
      reset({
        min_months: data.data.min_months,
        discount_percentage: data.data.discount_percentage,
        grace_period: data.data.grace_period,
      });
    }
  }, [data]);

  //Update ..... success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Box pt={"40px"} width="100%" maxWidth={"900px"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="min_months"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                autoFocus
                fullWidth
                size="small"
                label={t("minPaymentByMonths")}
                {...field}
                error={!!formState.errors.min_months}
                helperText={formState.errors.min_months?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
                sx={{ mb: 2 }}
              />
            )}
          />
          <Controller
            name="discount_percentage"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                label={t("yearlyDiscount")}
                {...field}
                error={!!formState.errors.discount_percentage}
                helperText={formState.errors.discount_percentage?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
                sx={{ mb: 2 }}
              />
            )}
          />
          <Controller
            name="grace_period"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                autoFocus
                fullWidth
                size="small"
                label={t("gracePeriod")}
                {...field}
                error={!!formState.errors.grace_period}
                helperText={formState.errors.grace_period?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
                sx={{ mb: 2 }}
              />
            )}
          />
          <Box display={"flex"} justifyContent={"end"} mt={4}>
            <Stack direction={"row"} spacing={1} width={200}>
              {/* <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={navigateToWebsiteCms}
            >
              {generalT("cancel")}
            </Button> */}
              <LoadingButton
                loading={isPendingUpdate}
                variant="contained"
                fullWidth
                type="submit"
              >
                {generalT("save")}
              </LoadingButton>
            </Stack>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Categories;
